@font-face {
  font-family: 'Technology';
  src: local('Technology'), url(./fonts/Technology.ttf) format('truetype');
}

html {
  font-family: Arial, sans-serif;
  font-size: 100%;
}

html, body, #root, .App {
  height: 100%;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #272E38;
}

.clock {
  display: flex;
  background: #0F1620;
  color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 5px;
}

.digits {
  display: flex;
}

.digit,
.separator {
  font-family: Technology, Arial, sans-serif;
  font-size: 5rem;
}

.digit {
  width: 36px;
  text-align: right;
  position: relative;
  z-index: 0;
}

.digit:before {
  color: #2a313b;
  position: absolute;
  content: "0";
  width: 40px;
  height: 70px;
  top: 0;
  right: 0;
  z-index: -1;
}

.label {
  color: #353f4d;
}

.message {
  font-size: 2rem;
}
